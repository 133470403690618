import React from "react";

import { Link } from "react-router-dom";

import { BookOpenIcon, CalendarIcon } from "@heroicons/react/outline";

import Intro from "../sections/home/Intro";

export default function Home(props) {
    return (
        <div>
            <div className="relative bg-green-900">
                <div className="absolute inset-0">
                    <img
                        className="h-full w-full object-cover"
                        src="https://source.unsplash.com/random/2500x1200/?forest+day+walk+sun+sunny+warm&auto=format&fit=crop&w=1920&q=60"
                        alt=""
                    />
                    <div
                        className="bg-white-900 absolute inset-0 mix-blend-multiply"
                        aria-hidden="true"
                    />
                </div>
                <div className="relative mx-auto max-w-6xl px-4 py-24 sm:px-6 sm:py-32 lg:px-8">
                    <div className="rounded-xl bg-white bg-opacity-90 px-4 pb-2 pt-8 sm:px-8 lg:w-1/2 xl:pr-16">
                        <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
                            <span className="block xl:inline">
                                <span className="text-green-700">Audrey</span>
                            </span>{" "}
                            <span className="block xl:inline"></span>
                            <span className="block xl:inline">
                                <span>Bauerlé</span>
                            </span>
                        </h1>
                        <p className="mx-auto mt-2 max-w-md text-lg text-gray-500 sm:text-xl md:mt-2 md:max-w-3xl">
                            Psychanalyste
                        </p>
                        <div className="mb-6 mt-8 flex flex-col space-y-2 sm:block sm:space-x-3">
                            <Link
                                to="/services"
                                className="transition-all-ease-in-out inline-flex items-center gap-x-2 rounded-md bg-green-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-md duration-300 hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
                            >
                                <BookOpenIcon
                                    className="-ml-0.5 h-5 w-5"
                                    aria-hidden="true"
                                />
                                Services
                            </Link>
                            {/* <Link
								to="/psychamarche"
								className="inline-flex items-center gap-x-2 rounded-md bg-green-50 py-2.5 px-3.5 text-sm font-semibold text-green-600 shadow-md hover:bg-green-100  transition-all-ease-in-out duration-300 text-center"
							>
								<MapIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
								Psychamarche
							</Link> */}
                            <Link
                                to="/evenements"
                                className="transition-all-ease-in-out inline-flex items-center gap-x-2 rounded-md bg-green-50 px-3.5 py-2.5 text-center text-sm font-semibold text-green-600  shadow-md duration-300 hover:bg-green-100"
                            >
                                <CalendarIcon
                                    className="-ml-0.5 h-5 w-5"
                                    aria-hidden="true"
                                />
                                Événements
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <Intro loader={props.loader} />
        </div>
    );
}
