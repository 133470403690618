import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";

import { getSingleton } from "../cms";

export default function Footer(props) {
    let [urlInstagram, setInstagramUrl] = useState("");
    let [urlFacebook, setFacebookUrl] = useState("");
    let [urlLinkedin, setLinkedinUrl] = useState("");

    useEffect(() => {
        getSingleton("contact")
            .then((data) => {
                setInstagramUrl(data.instagram);
                setFacebookUrl(data.facebook);
                setLinkedinUrl(data.linkedin);
            })
            .catch((err) => console.error(err));
    }, []);

    let facebookIcon = (
        <svg
            fill="currentColor"
            viewBox="0 0 24 24"
            className="h-6 w-6"
            aria-hidden="true"
        >
            <path
                fillRule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clipRule="evenodd"
            />
        </svg>
    );

    let instagramIcon = (
        <svg
            fill="currentColor"
            viewBox="0 0 24 24"
            className="h-6 w-6"
            aria-hidden="true"
        >
            <path
                fillRule="evenodd"
                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                clipRule="evenodd"
            />
        </svg>
    );

    let linkedinIcon = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 16 16"
            className="h-5 w-5"
            aria-hidden="true"
        >
            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
        </svg>
    );

    return (
        <div>
            <div className="relative">
                <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                >
                    <div className="w-full border-t border-gray-300" />
                </div>
            </div>

            <footer className="bg-white">
                <div className="mx-auto max-w-7xl overflow-hidden px-4 py-12 sm:px-6 lg:px-8">
                    <nav
                        className="-mx-5 -my-2 flex flex-wrap justify-center"
                        aria-label="Footer"
                    >
                        {props.routes.map((route) => (
                            <div key={route.id} className="px-5 py-2">
                                <Link
                                    to={route.path}
                                    className="text-base text-gray-500 transition-colors hover:text-gray-900"
                                >
                                    {route.name}
                                </Link>
                            </div>
                        ))}
                    </nav>
                    <div className="mt-8 flex items-center justify-center space-x-6">
                        <a
                            key="facebook"
                            href={urlFacebook}
                            className="text-gray-400 transition-colors hover:text-gray-500"
                        >
                            <span className="sr-only">Facebook</span>
                            {facebookIcon}
                        </a>
                        <a
                            key="instagram"
                            href={urlInstagram}
                            className="text-gray-400 transition-colors hover:text-gray-500"
                        >
                            <span className="sr-only">Instagram</span>
                            {instagramIcon}
                        </a>
                        <a
                            key="linkedin"
                            href={urlLinkedin}
                            className="text-gray-400 transition-colors hover:text-gray-500"
                        >
                            <span className="sr-only">LinkedIn</span>
                            {linkedinIcon}
                        </a>
                    </div>
                    <p className="mt-8 text-center text-base text-gray-400">
                        <span className="block">
                            &copy; {new Date().getFullYear()} Audrey Bauerlé.
                            Tous droits réservés.{" "}
                        </span>
                        <span className="block">
                            Conçu par{" "}
                            <a
                                className="text-gray-400 transition-colors hover:text-gray-500"
                                href="https://princelle.org"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Maxime Princelle
                            </a>
                            .
                        </span>
                    </p>
                </div>
            </footer>
        </div>
    );
}
